/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import FontUrl1 from "../fonts/FranklinGothic/itcfranklingothicstd-bkcd.woff2"
import FontUrl2 from "../fonts/FranklinGothic/itcfranklingothicstd-book.woff2"
import FontUrl3 from "../fonts/FranklinGothic/itcfranklingothicstd-demi.woff2"
import FontUrl4 from "../fonts/FranklinGothic/itcfranklingothicstd-dmcd.woff2"
import FontUrl5 from "../fonts/FranklinGothic/itcfranklingothicstd-dmcp.woff2"
import FontUrl6 from "../fonts/Tungsten/Tungsten-Bold.woff2"

import SocialShareImage from "../images/socialshareimage-pt.jpg"

const ogimage = "http://stage.snickershungerinsurance.com" + SocialShareImage

function Seo({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  //const defaultTitle = site.siteMetadata?.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      //titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `https://stage.snickershungerinsurance.com/`,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogimage,
        },
        {
          property: `og:image:secure_url`,
          content: ogimage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: ogimage,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    >
      <link
        rel="preload"
        as="font"
        href={FontUrl1}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FontUrl2}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FontUrl3}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FontUrl4}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FontUrl5}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FontUrl6}
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `pt`,
  meta: [],
  keywords: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default Seo
