import React from "react"
import { navigate } from "gatsby"
import Cookies from "universal-cookie"

import Layout from "../../components/layoutPt"
import Seo from "../../components/seoPt"

import Agegate from "../../components/agegatePt"

const AgeGate = () => {
  let ageCookie = new Cookies()

  if (ageCookie.get("isOldEnough")) {
    navigate("/pt")
  }

  return (
    <Layout>
      <Seo
        title="Barreira de idade | Chama no Snickers"
        lang="pt"
        description="Vacilos causados pela fome podem acontecer enquanto você joga. Felizmente, seus vacilos podem render Snickers na faixa! Conta o que rolou em chamanosnickers.com.br!"
      />

      <Agegate />
    </Layout>
  )
}

export default React.memo(AgeGate)
